.banner {
  .hero-body {
  }
}

.photo-page {
  .is2by3 img {
    max-width: 1000px;
  }
  .is4by3 img {
    max-width: 1500px;
  }
}

.pricing-table .pricing-plan {
  flex: 1;
}

.image.logo img {
  width: auto;
}

footer.footer {
  position: relative;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
  .content {
    position: relative;
    z-index: 2;
  }
}